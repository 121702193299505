<template>
  <div class="welcome main__wrapper">
    <div class="block_container welcome__wrapper">
      <div class="welcome__content">
        <h1 class="title-h1">
          {{ $t('main.main_title') }}
        </h1>
        <div class="title-h5 welcome__text">
          {{ $t('main.main_subtitle') }}
        </div>
        <input-search
          class="welcome__search"
          :placeholder="$t('main.main_placholder')"
          no-border
          @detectSearchValue="searchData"
          :searchResultData="getSearchResultData"
        />
      </div>
    </div>
    <div class="welcome__img">
      <img src="images/home_main2.png" alt="houses" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getSearchResultData']),
  },
  methods: {
    async searchData(value) {
      if (!!value) {
        await this.$store.dispatch('getSearchResultsData', value);
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.welcome
  background: transparent
  display: flex
  position: relative
  height: calc(100vh - 1.2rem)
  @include s
    align-self: center

.welcome__wrapper
  display: flex
  flex-direction: column
  justify-content: center
  width: 100%
  height: calc(100vh - 12rem)
  @include s
    text-align: center
    justify-content: start
    align-items: center
  .icon-magnifier
    display: none

.welcome__content
  position: relative
  z-index: 99
  max-width: 80rem
  @include s
    margin-top: 10rem
    justify-content: center

.welcome__text
  margin-top: 1.6em

.welcome__search
  background: $white
  border-radius: 5rem
  margin-top: 8rem
  max-width: 50rem
  border-bottom: 0
  @include s
    margin: 4rem auto

.welcome__img img
  mix-blend-mode: multiply

.welcome__img
  position: absolute
  bottom: 0
  right: 0
  @include s
    display: none
</style>
