<template>
  <div>
    <Welcome />
    <News />
    <Statistic />
    <Presentations />
    <Questions />
  </div>
</template>

<script>
import News from '@/components/landing/news/News.vue'
import Welcome from '@/components/landing/welcome/Welcome.vue'
import Questions from '@/components/landing/questions/Questions.vue'
import Statistic from '@/components/landing/statistic/Statistic.vue'
import Presentations from '@/components/landing/statistic/Presentations.vue'

export default {
  components: { Statistic, News, Welcome, Questions, Presentations },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style lang="sass"></style>

