<template>
  <div class="main__wrapper presentations presentations__wrapper">
    <div class="block_container">
      <h1 class="presentations__title title-h2">
        {{ $t('main.presentation') }}
      </h1>
      <div
        class="presentations__list-wrapper"
        v-if="!!presentationsList.length"
      >
        <div class="presentations__list">
          <div
            class="presentation__item-wrapper"
            v-for="item in presentationsList"
            :key="item.uuid"
            @click="schowDocument(item.attachment.original_url)"
          >
            <div class="presentations__img-block">
              <!-- <template v-if="item.attachment.mime.includes('image')">
              <img
                :src="item.attachment.original_url"
                class="presentations__img"
              />
            </template> -->
              <div class="presentations__ico">
                <svg
                  width="55"
                  height="61"
                  viewBox="0 0 55 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="presentations__svg"
                >
                  <path
                    d="M0 3.0256C0 1.3542 1.36583 0 3.0525 0H39.7222L54.9999 15.25V57.9286C55.0027 58.3292 54.9265 58.7264 54.7755 59.0975C54.6246 59.4686 54.4019 59.8064 54.1201 60.0916C53.8384 60.3768 53.5031 60.6038 53.1335 60.7597C52.7638 60.9155 52.367 60.9972 51.9658 61H3.03416C2.23171 60.9944 1.46366 60.674 0.895942 60.1078C0.328221 59.5417 0.00640031 58.7754 0 57.9744V3.0256ZM6.1111 6.1V54.9H48.8888V18.3H39.7222V36.6H21.3889V42.7H15.2778V18.3H36.6666V6.1H6.1111ZM21.3889 24.4V30.5H33.6111V24.4H21.3889Z"
                  />
                </svg>
              </div>
            </div>
            <div class="presentations__name-block">
              {{
                item.title.length >= nameMaxLength
                  ? `${item.title.slice(0, nameMaxLength)}...`
                  : item.title
              }}
            </div>
          </div>
        </div>
        <div class="presentations__btn-block" v-if="isShowButtons">
          <Button
            transparent
            @click.native="loadMore"
            v-if="isShowMore"
            class="presentations__btn"
          >
            {{ $t('main.load_more') }}
          </Button>
          <Button
            transparent
            @click.native="loadLess"
            v-else
            class="presentations__btn"
          >
            {{ $t('main.to_hide') }}
          </Button>
        </div>
      </div>

      <NoPresentations v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NoPresentations from '@/elements/NoPresentations.vue';

export default {
  components: { NoPresentations },
  data() {
    return {
      nameMaxLength: 25,
    };
  },

  async created() {
    await this.$store.dispatch('getPresentations', { page: 1, isReset: true });
  },

  computed: {
    ...mapGetters(['getPresentations', 'getPresentationsItemsNumber']),

    presentationsList() {
      return this.getPresentations?.items;
    },

    isShowMore() {
      return this.getPresentations?.total > this.getPresentations?.to_item;
    },
    isShowButtons() {
      return this.getPresentationsItemsNumber < this.getPresentations?.total;
    },
  },

  methods: {
    schowDocument(url) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'pdf-view',
        size: 'xl',
        props: { url, isPdfViewer: true },
      });
    },

    loadMore() {
      let page = this.getPresentations?.current_page + 1;
      this.$store.dispatch('getPresentations', { page });
    },
    loadLess() {
      this.$store.dispatch('getPresentations', { page: 1, isReset: true });
    },
  },
};
</script>

<style lang="sass">
.presentations__wrapper
  padding-bottom: 5rem

.presentations__title
  margin-bottom: 7rem
  @include s
    text-align: center
    margin-bottom: 4rem

.presentations__list
  display: flex
  flex-wrap: wrap
  @include s
    justify-content: center

.presentation__item-wrapper
  margin-right: 20px
  margin-bottom: 20px
  width: 230px
  border: solid 1px $gray
  cursor: pointer
  &:hover
    .presentations__img
      transition: transform 0.3s
      transform: scale(1.1)

    .presentations__svg
      opacity: 1
      transition: opacity 0.3s

  @include s
    margin-right: 10px
    margin-left: 10px

.presentations__name-block
  padding: 5px 7px
  line-height: 16px
  display: flex
  align-items: center
  justify-content: center
  height: 44px
  max-height: 44px
  border-top: solid 1px $gray
  background: #FAFAFA

.presentations__img-block
  overflow: hidden
  height: 190px

.presentations__img
  width: 100%
  transition: transform 0.15s

.presentations__ico
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center

.presentations__svg
  fill: $black
  opacity: 0.5
  transition: opacity 0.15s

.presentations__btn
  margin: 50px auto 0 auto
</style>
