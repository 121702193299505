<template>
  <div
    id="statistic"
    class="statistic-main main__wrapper statistic-main__wrapper"
  >
    <div class="block_container">
      <div class="statistic-main__title-wrapper">
        <h2 class="title-h2 statistic__title">
          {{ $t('sections.statistic_home', {y: this.getStatisticTotal.year || ''}) }}
        </h2>
      </div>

      <div class="statistic__links">
        <div class="statistic__links-item" v-for="item in statisticPages" :key="item.id">
          <router-link
            class="statistic__link"
            :to="`/statistic/${item.id}`"
          >
            <div class="statistic__link-top">
              <div class="statistic__link-desc">{{ $t(`statistic.${item.type}_link_desc`) }}</div>
              <div class="statistic__link-num" v-html="item.num"></div>
            </div>
            <div class="statistic__link-bottom">
              <div class="statistic__link-title">{{ $t(`statistic.${item.type}`) }}</div>
              <svg-icon
                class="statistic__link-icon"
                name="arrow"
              />
            </div>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { numberFormat } from '@/library/helpers.js'

export default {

  data() {
    return {}
  },

  async created() {
    await this.$store.dispatch('fetchStatisticTotal');
  },

  computed: {
    ...mapGetters(['getStatisticPages', 'getStatisticTotal']),

    statisticPages() {
      const nums = {
        tax_revenues: `${numberFormat(this.getStatisticTotal?.tax_revenues?.total_sum / 1000 || 0, 2, 3, ' ', ',')} <span class="small">${this.$t('main.finance.thousands')} ${this.$t('main.finance.UAH')}</span>`,
        domestic_tourism: numberFormat(this.getStatisticTotal?.tax_revenues?.total_taxpayers_paid || 0, 0, 3, ' ', '.'),
        inbound: numberFormat(this.getStatisticTotal?.inbound_tourism?.number_of_tourists || 0, 0, 3, ' ', '.'),
        outbound: numberFormat(this.getStatisticTotal?.outbound_tourism?.number_of_tourists || 0, 0, 3, ' ', '.'),
      }

      return this.getStatisticPages.map(row => {
        row.num = nums[row.type]

        return row
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.statistic-main__wrapper
  padding-bottom: 5rem

.statistic-main__title-wrapper
  display: flex
  justify-content: space-between
  margin-bottom: 7rem
  align-items: center

  @include xs
    margin-bottom: 32px

.statistic
  &__links
    display: flex
    flex-wrap: wrap
    gap: 80px 42px

    @include xs
      gap: 40px 0

    &-item
      width: calc(50% - 21px)

      @include xs
        width: 100%

  &__link
    display: flex
    flex-direction: column
    width: 100%
    max-width: 500px
    color: #111

    &:hover
      .statistic__link-title
        text-decoration: underline

      .statistic__link-icon
        opacity: 1

    &-top
      border-bottom: 2px solid #000

    &-desc
      max-width: 370px
      font-size: 18px

      @include xs
        font-size: 14px

    &-num
      font-size: 4.8rem
      line-height: 1
      padding: 40px 0
      white-space: nowrap

      @include m
        font-size: 3.25rem
        padding: 20px 0

      ::v-deep
        .small
          font-size: 3rem

          @include m
            font-size: 2rem

    &-bottom
      padding-top: 20px
      display: flex
      justify-content: space-between

    &-title
      font-size: 2rem
      line-height: 1
      font-weight: 400

      @include xs
        font-size: 18px

    &-icon
      opacity: 0
      transition: all .3s ease 0s

      @include m
        opacity: 1
        width: 22px
        height: 22px

</style>
