<template>
  <div class="no_presentations no_presentations__wrapper">
    <div class="no_presentations__img">
      <svg
        width="143"
        height="185"
        viewBox="0 0 143 185"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="71.5" cy="71.5" r="71.5" fill="#F1F1F1" />
        <rect
          x="43.4515"
          y="70.0427"
          width="70"
          height="98"
          rx="7"
          transform="rotate(2.5 43.4515 70.0427)"
          fill="#EEEEEE"
          stroke="#999999"
          stroke-width="2"
        />
        <rect
          x="1.83506"
          y="64.1801"
          width="74"
          height="102"
          rx="9"
          transform="rotate(-7.5 1.83506 64.1801)"
          fill="#FAFAFA"
          stroke="#999999"
          stroke-width="2"
        />
        <path
          d="M4.00124 72.9726C3.42454 68.5921 6.50811 64.5735 10.8886 63.9968L66.4095 56.6873C70.79 56.1106 74.8086 59.1942 75.3853 63.5747L77.3432 78.4464L5.95913 87.8442L4.00124 72.9726Z"
          fill="#EEEEEE"
        />
        <rect
          x="89.8672"
          y="58.6426"
          width="3"
          height="10"
          rx="1.5"
          transform="rotate(82.5 89.8672 58.6426)"
          fill="#999999"
        />
        <rect
          x="62.4688"
          y="42.0781"
          width="3"
          height="10"
          rx="1.5"
          transform="rotate(-7.5 62.4688 42.0781)"
          fill="#999999"
        />
        <rect
          x="80.0469"
          y="44.8066"
          width="3"
          height="10"
          rx="1.5"
          transform="rotate(37.5 80.0469 44.8066)"
          fill="#999999"
        />
        <path
          d="M26.271 105.795C26.1213 104.765 26.8356 103.808 27.8674 103.658L50.3004 100.399L61.0125 108.443L64.8349 134.753C64.8724 135 64.8614 135.252 64.8023 135.494C64.7431 135.736 64.6372 135.964 64.4903 136.165C64.3435 136.366 64.1587 136.536 63.9466 136.665C63.7344 136.793 63.499 136.879 63.2537 136.916L33.3194 141.265C32.8279 141.333 32.3294 141.204 31.9314 140.905C31.5334 140.607 31.2679 140.163 31.1922 139.67L26.271 105.795ZM30.2848 107.147L34.6554 137.231L60.8251 133.429L57.5472 110.866L51.9394 111.681L53.5783 122.963L42.3628 124.592L42.9091 128.352L39.1705 128.896L36.9853 113.853L50.0701 111.953L48.9775 104.431L30.2848 107.147ZM41.2701 117.071L41.8164 120.831L49.2935 119.745L48.7472 115.985L41.2701 117.071Z"
          fill="#EEEEEE"
        />
      </svg>
    </div>
    <div class="no_presentations__txt">
      {{ $t('main.no_presentations') }}
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.no_presentations__wrapper
  display: flex
  flex-direction: column
  align-items: center
.no_presentations__img
  margin-bottom: 20px
.no_presentations__txt
  color: #C4C4C4
  font-size: 18px
</style>
