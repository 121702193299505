<template>
  <div id="news" class="news__wrapper main__wrapper">
    <div class="block_container">
      <div class="news__title-wrapper">
        <h2 class="title-h2">{{ $t(`profile.news`) }}</h2>
        <div
          class="details-block details-block__wrapper"
          @click="$router.push({ name: 'news-all' })"
        >
          <h5 class="title-h5 details-link-news details-block__link">
            {{ $t('main.all_news') }}
          </h5>
          <svg-icon
            class="details-icon-white details-block__icon"
            name="arrow"
          />
        </div>
      </div>
      <ul class="news__container">
        <li
          v-for="(item, idx) in getLatestNewsData"
          :key="item.uuid"
          class="news__element"
          :class="{ news__element_main: isHasMain ? item.main : idx === 0 }"
          @click="goToNewsDetails(item.uuid)"
        >
          <img
            v-if="isHasMain ? item.main : idx === 0"
            :src="item.file"
            class="news__img first-element-image"
            alt="news"
          />
          <h5
            class="news__date subtitle"
            :class="{ news__date_main: isHasMain ? item.main : idx === 0 }"
            v-if="item.created_at"
          >
            {{ getCurrentTimeZoneDate(item.created_at).date }}
          </h5>
          <p
            class="news__title"
            :class="{ news__title_main: isHasMain ? item.main : idx === 0 }"
          >
            {{ item.name }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { convertDateToCurrentTimeZone } from '@/library/time'
export default {
  async mounted() {
    await this.$store.dispatch('getLatestNewsData')
  },
  computed: {
    ...mapGetters(['getLatestNewsData']),

    isHasMain() {
      return this.getLatestNewsData?.some((el) => el.main)
    },
    getCurrentTimeZoneDate() {
      return convertDateToCurrentTimeZone
    },
  },

  methods: {
    goToHome() {
      this.$router.push({ name: 'landing-page' })
    },
    goToNewsDetails(id) {
      this.$router.push(`/news/${id}`)
    },
  },
}
</script>

<style lang="sass" scoped>
.news__wrapper
  background-color: $black
  color: white
.news__title-wrapper
  display: flex
  justify-content: space-between
  margin-bottom: 30px

.details-link-news
  color: white
.news__container
  display: flex
  justify-content: space-between
  gap: 4rem
  @include s
    flex-direction: column

.news__element
  order: 1
  padding-top: 23px
  position: relative
  width: 25%
  &:before
    content: ''
    position: absolute
    height: 2px
    top: 0
    width: 100%
    background-color: $white

  @include s
    padding-top: 0px
    width: 100%
    max-width: 100%

.news__element_main
  order: 0
  width: 50%
  padding-top: 0
  &:before
    width: 0px
  @include s
    width: 100%
    max-width: 100%

.news__title
  font-size: 18px
  font-weight: 400
  line-height: 28px
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  margin-bottom: 4rem
  cursor: pointer
  &:hover
    text-decoration: underline

  @include s
    margin-bottom: 0

.news__title_main
  font-size: 28px
  font-weight: 400
  line-height: 48px
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  @include xs
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: left

.news__date
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 24px
  padding-bottom: 16px
  @include s
    padding: 20px 0 12px 0

.news__date_main
  padding: 24px 0 16px 0

.news__img
  width: 100%
</style>

